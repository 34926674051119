<template>
    <div id="user-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <form @submit.prevent="getList()">
                <div class="mb-3 row">
                    <div class="col-sm-3">
                        <h5 class="border-bottom text-primary border-primary d-inline-block border-2 mb-3 mb-sm-0">Visit
                            List</h5>
                    </div>
                    <div class="col-sm-3 ms-auto">
                        <input v-model="table.search" type="text" class="form-control mb-3" id="name"
                               placeholder="Searching...">
                    </div>
                    <div class="col-sm-2">
                        <input type="submit" class="btn btn-primary w-100" value="Searching">
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Patient</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Doctor</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists" class="align-middle">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.date }}</td>
                                <td>
                              <span v-if="list.patient">
                                {{ list.patient.name + ' | ' + list.patient.id_number }}
                             </span>
                                </td>
                                <td>{{ list.patient.phone }}</td>
                                <td>{{ list.doctor.name }}</td>
                                <td>
                                    <div class="d-flex justify-content-around align-items-center">
                                        <view-action v-if="$root.$can('visit', 'visit-view')"
                                                     to_name="prescriptionPreview" :id="list.id"></view-action>
                                        <update-action v-if="$root.$can('visit', 'visit-update')" to_name="prescriptionEdit"
                                                       :params="{ id: list.id }"/>
                                        <delete-action v-if="$root.$can('visit', 'visit-delete')" class="w-auto"
                                                       :id="list.id"/>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "StaffList",
    components: {Pagination},
    data      : () => ({
        lists: [],
        table: {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        }
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page      : this.table.pagination.current_page,
                patient_id: this.$route.query.patient_id,
                doctor_id : this.$route.query.doctor_id,
            };
            ApiService.get('/prescriptions', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/prescriptions/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        //-------CRUD--------
    }
}
</script>
